import types from '../mutation-types';
import ConnectedContactsAPI from '../../api/connectedContacts';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getConnectedContacts: _state => _state.records,
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  async get({ commit }) {
    commit(types.SET_CONNECTED_CONTACTS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await ConnectedContactsAPI.get();
      commit(types.SET_CONNECTED_CONTACTS, { data: data.payload });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CONNECTED_CONTACTS_UI_FLAG, { isFetching: false });
    }
  },
};

export const mutations = {
  [types.SET_CONNECTED_CONTACTS_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.SET_CONNECTED_CONTACTS]($state, { data }) {
    $state.records = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
